import $ from "jquery";
import 'slick-carousel/slick/slick.css';
import "slick-carousel";

$(document).ready(function(){
  var slider = $('.index-slider');
  slider.slick({
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1500,
    fade: true
  });
});